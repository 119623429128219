import React from 'react'

const Benifits = ({ workContent }) => (
  <div className="mb-10">
    <div className="md:icons-grid lg:block xl:icons-grid">
      {workContent.work_list.map((item, i) => (
        <div className="flex items-center mb-8" key={i}>
          {item.list_icon && (
            <div className="w-10 md:w-16 flex-no-shrink h-10 md:h-16">
              <img
                className="w-full h-full"
                src={item.list_icon.url}
                alt={`Icon for ${item.list_icon.title}`}
              />
            </div>
          )}
          {item.list_item && (
            <p className="pl-4 lg:pr-6 md:w-2/3">{item.list_item}</p>
          )}
        </div>
      ))}
    </div>
  </div>
)

export default Benifits
