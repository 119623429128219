import React from 'react'

const DownloadIcon = props => (
  <svg fill="none" className="h-4 w-4" viewBox="0 0 12 18" {...props}>
    <g clip-path="url(#a)">
      <path
        d="M9.3 6.477v.57h1.8v9.103H.9V7.046h1.8v-.569H.3V16.72h11.4V6.477H9.3ZM5.7 2.12v8.909h.6V2.126l2.567 2.83.455-.371L5.998.92 2.665 4.584l.454.372L5.7 2.12Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(0 .75)" d="M0 0h12v16.5H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default DownloadIcon
