import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout.js'
import Hero from '../components/hero.js'
import Benifits from '../components/Benifits.js'
import DownloadIcon from '../components/SVG/DownloadIcon.js'
import { formatId } from '../utils/format-id.js'
import { Link } from 'gatsby'
import RightArrow from '../components/SVG/RightArrow.js'

import SEOPage from '../components/SEO/Page.js'

class WorkPage extends React.Component {
  render() {
    const { location, data } = this.props

    const workContent = data.prismicWork.data

    var heroImgs = []
    var heroImgsMobile = []

    workContent.hero &&
      workContent.hero.forEach(item => {
        heroImgs.push(item.desktop_image.url)
        heroImgsMobile.push(item.mobile_image.url)
      })

    var backgroundStyle = {
      backgroundImage: `url(${
        workContent.vacancies_image ? workContent.vacancies_image.url : ''
      })`,
      backgroundSize: `cover`,
    }

    // create a list of the remaining vacancies
    let vacancies = []
    workContent.vacancies &&
      workContent.vacancies.forEach((item, index) => {
        if (index > 0) {
          vacancies.push(item)
        }
      })

    return (
      <Layout location={location}>
        <SEOPage
          title={workContent.title && workContent.title}
          location={location}
        />
        <Hero heroImgs={heroImgs} heroImgsMobile={heroImgsMobile} />

        <div
          id="why-forte"
          className="mt-12 lg:mt-25 lg:pt-10 mb-12 lg:mb-40 px-3 lg:px-14"
        >
          <div className="lg:flex lg:-mx-2">
            {workContent.work_title && (
              <div className="lg:w-1/2 lg:px-2">
                <h3 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl text-grey font-light leading-tight">
                  {workContent.work_title}
                </h3>
              </div>
            )}
            <div className="lg:w-1/2 lg:px-2 text-lg leading-lg">
              {workContent.work_lead && workContent.work_lead.html && (
                <div
                  className="mb-24 rte"
                  dangerouslySetInnerHTML={{
                    __html: workContent.work_lead.html,
                  }}
                />
              )}
              {workContent.work_list_title && (
                <h4 className="text-2xl font-light text-grey mb-8">
                  {workContent.work_list_title}
                </h4>
              )}
              {workContent.work_list && workContent.work_list.length && (
                <Benifits workContent={workContent} />
              )}
              {workContent.work_text && workContent.work_text.html && (
                <div
                  className="rte"
                  dangerouslySetInnerHTML={{
                    __html: workContent.work_text.html,
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div id="current-vacancies" className="lg:flex mb-16 lg:mb-24">
          <div className="lg:w-1/2">
            <div className="h-full" style={backgroundStyle} />
          </div>
          <div className="lg:w-1/2 bg-grey text-white">
            <div className="w-full h-full pt-16 lg:pt-24 pb-16 pl-3 pr-5 lg:px-19 lg:flex flex-col justify-between">
              {workContent.vacancies_title && (
                <h2 className="text-4xl font-light mb-8">
                  {workContent.vacancies_title}
                </h2>
              )}
              {workContent.vacancies &&
                workContent.vacancies.map((vacancy, index) => (
                  <div
                    key={index}
                    className={`${
                      index <
                      (workContent.vacancies
                        ? workContent.vacancies.length
                        : 0) -
                        1
                        ? 'mb-5 pb-6 border-grey-light border-b'
                        : 'mb-5 pb-6'
                    }`}
                  >
                    {vacancy.vacancy_title && (
                      <h3 className="text-lg font-medium">
                        {vacancy.vacancy_title}
                      </h3>
                    )}
                    {vacancy.vacancy_description && (
                      <p className="mb-2 pt-3 leading-lg">
                        {vacancy.vacancy_description}
                      </p>
                    )}
                    <div className="flex items-center mt-5">
                      {vacancy.vacancy_link && vacancy.vacancy_link.url && (
                        <a
                          href={vacancy.vacancy_link.url}
                          className="px-3 py-2 bg-white no-underline text-grey w-auto text-sm"
                        >
                          Apply Now
                        </a>
                      )}
                      {vacancy.vacancy_download &&
                        vacancy.vacancy_download.url && (
                          <a
                            href={vacancy.vacancy_download.url}
                            className="flex px-3 py-2 border-white border ml-6 no-underline text-white text-sm"
                          >
                            <p className="pr-2">Download Job Description</p>
                            <DownloadIcon />
                          </a>
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          id={workContent?.lead_title ? formatId(workContent?.lead_title) : ''}
          className="mt-12 lg:pt-25 lg:mb-3 mb-20 lg:mb-40 px-3 lg:px-14"
        >
          <div className="lg:flex lg:-mx-2">
            {workContent.lead_title && (
              <div className="lg:w-1/2 lg:px-2">
                <h1 className="mb-12 lg:mb-20 lg:w-4/5 text-4xl font-light leading-tight text-grey">
                  {workContent.lead_title}
                </h1>
              </div>
            )}
            <div className="lg:w-1/2">
              {workContent.lead_text && workContent.lead_text.html && (
                <div
                  className="lg:px-2 rte text-lg leading-lg"
                  dangerouslySetInnerHTML={{
                    __html: workContent.lead_text.html,
                  }}
                />
              )}
              {workContent.button && workContent.button.url && (
                <Link
                  className="text-black bg-green hover:opacity-75 focus:opacity-75 outline-none transition p-4 flex items-center no-underline mt-10"
                  to={workContent.button.url}
                >
                  <span className="flex-1 mr-2">
                    <p>{workContent.button_title}</p>
                  </span>
                  <RightArrow className="svg-charcoal" />
                </Link>
              )}
              {workContent.secondary_button &&
                workContent.secondary_button.url && (
                  <Link
                    className="text-black bg-green hover:opacity-75 focus:opacity-75 outline-none transition p-4 flex items-center no-underline mt-5"
                    to={workContent.secondary_button.url}
                  >
                    <span className="flex-1 mr-2">
                      <p>{workContent.secondary_button_title}</p>
                    </span>
                    <RightArrow className="svg-charcoal" />
                  </Link>
                )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export const pageWorkQuery = graphql`
  query {
    prismicWork {
      data {
        title
        hero {
          desktop_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
          mobile_image {
            localFile {
              childImageSharp {
                id
              }
            }
            url
          }
        }
        work_title
        work_lead {
          html
        }
        work_list_title
        work_list {
          list_item
          list_icon {
            url
            localFile {
              childImageSharp {
                id
              }
            }
          }
        }
        work_text {
          html
        }
        vacancies_image {
          url
          localFile {
            childImageSharp {
              id
            }
          }
        }
        vacancies_title
        vacancies {
          vacancy_title
          vacancy_description
          vacancy_download {
            url
          }
          vacancy_link {
            url
          }
        }
        lead_title
        lead_text {
          html
          text
        }
        button_title
        button {
          url
        }
        secondary_button_title
        secondary_button {
          url
        }
      }
    }
  }
`

export default WorkPage
